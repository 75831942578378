.App {
  padding: 10px;
  font-family: "PT Sans";
  color: #353535;
  display: flex;
  
  min-height: 500px;
  flex-direction: column;
}
.table-str {
  border: 1px solid #2e3035;
  border-radius: 8px;
  padding: 16px;
  width: 52%;
  text-align: center;
}